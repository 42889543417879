<template>
  <section v-if="loaded && gameCount">
    <transition name="fade">
      <div class="loading" v-if="!banner">
        <!--img src="@/assets/img/loading.gif" /-->
        <transition name="fade">
          <ui-swiper v-if="banner"/>
        </transition>
      </div>
    </transition>

    <div class="scrNavi">
      <section class="main_iv" @click="onCasinoSelectGame('minigame', 'speed')">
        <!-- <div class="main_ment">
          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
            <div class="swiper-slide">
              <h1><strong style="color:#1d294f">수퍼토너먼트</strong>상품</h1><br>
              <h3>라이브카지노의 세계로 안내합니다.</h3>
              <p>안정된 속도와 서비스로<br>실제 카지노의 생생함 그대로</p>
              <button>서비스 바로가기</button><br><br>
            </div>
            <div class="swiper-slide">
              <h1><strong style="color:#1d294f">수퍼토너먼트</strong>상품</h1><br>
              <h3>라이브카지노의 세계로 안내합니다.</h3>
              <p>안정된 속도와 서비스로<br>실제 카지노의 생생함 그대로</p>
              <button>서비스 바로가기</button><br><br>
            </div>
            </div>
          </div>
        </div> -->
        <div class="w_img"><img src="../assets/img/banimg_1.png"></div>
        <div class="main_txt">
          <img src="@/assets/img/banimg_text.png">
        </div>
        <div class="bg">
          <img src="../assets/img/banimg_6.png">
          <img src="../assets/img/banimg_5.png">
          <img src="../assets/img/banimg_4.png">
        </div>
      </section>

      <ul class="navi tabs">
        <!--li @click="currentTab = 'tab-1'" :class="{'on': currentTab == 'tab-1'}" data-tab="tab-1"><em class="all"></em><span>{{$t('front.gameCategory.all')}}</span></li-->
        <li @click="currentTab = 'tab-0'"  :class="{'on': currentTab == 'tab-0'}" data-tab="tab-0"><a><span>홀덤</span></a></li>
        <li @click="currentTab = 'tab-1'"  :class="{'on': currentTab == 'tab-1'}" data-tab="tab-1"><a><span class="ft13">라이브<br />스피드 게임</span></a></li>
        <li @click="currentTab = 'tab-3'"  :class="{'on': currentTab == 'tab-3'}" data-tab="tab-1"><a><span>니우니우</span></a></li>
        <li @click="currentTab = 'tab-2'"  :class="{'on': currentTab == 'tab-2'}" data-tab="tab-2"><a><span>에볼루션</span></a></li>
        <!--li @click="currentTab = 'tab-2'" v-if="gameCount['casino']" :class="{'on': currentTab == 'tab-2'}" data-tab="tab-2"><a><em class="live"></em><span>{{$t('front.gameCategory.livecasino')}}</span></a></li>
        <li @click="currentTab = 'tab-3'" v-if="gameCount['hc-casino']" :class="{'on': currentTab == 'tab-3'}" data-tab="tab-3"><a><em class="hotel"></em><span>{{$t('front.gnb.hotelcasino')}}</span></a></li> -->
        <li @click="currentTab = 'tab-4'" v-if="gameCount['slot']" :class="{'on': currentTab == 'tab-4'}" data-tab="tab-4"><a><em class="slot"></em><span>{{$t('front.gameCategory.slot')}}</span></a></li>
        <li @click="currentTab = 'tab-7'" v-if="gameCount['h-slot']" :class="{'on': currentTab == 'tab-7'}" data-tab="tab-7"><a><em class="hotelslot"></em><span>{{$t('front.gnb.hotelslot')}}</span></a></li>
        <li @click="currentTab = 'tab-5'" v-if="gameCount['sports']" :class="{'on': currentTab == 'tab-5'}" data-tab="tab-5"><a><em class="sport"></em><span>{{$t('front.gnb.sport')}}</span></a></li>
        <!--li @click="currentTab = 'tab-6'" v-if="gameCount['mini-game']" :class="{'on': currentTab == 'tab-6'}" data-tab="tab-6"><a><em class="mini"></em><span>{{$t('front.gnb.minigame')}}</span></a></li-->
      </ul>
    </div>
    <div class="tab-content tab-0" v-if="currentTab == 'tab-0'">
      <ul class="gamewrap">
        <li><a @click="onCasinoSelectGame('minigame', 'hol')"></a></li>
      </ul>
    </div>
    <div class="tab-content tab-1" v-if="currentTab == 'tab-1'">
      <ul class="gamewrap">
        <li><a @click="onCasinoSelectGame('minigame', 'speed')"></a></li>
      </ul>
    </div>
    <div class="tab-content tab-3" v-if="currentTab == 'tab-3'">
      <ul class="gamewrap">
        <li><a @click="onCasinoSelectGame('minigame', 'niu')"></a></li>
      </ul>
    </div>
    <div class="tab-content tab-2" v-if="currentTab == 'tab-2'">
      <ul class="gamewrap">
       <li class="casino">
         <img src="@/assets/img/game_main_pc_1_off.png" />
         <p class="name">
          에볼루션
          <em class="casino">{{ $t('front.gnb.livecasino') }}</em>
         </p>
         <a @click="onCasinoSelectGame(casino, 1)"></a>
       </li>
      </ul>
    </div>
    <div class="tab-content tab-4" v-if="currentTab == 'tab-4'">
      <ul class="gamewrap">
        <template v-for="game in gameList" v-bind:key="game.code">
          <li v-if="currentTab == 'tab-1' || (currentTab != 'tab-1' && currentTab==game.type)"
             :class="game.groupCode">
            <img :src="getBackgroundImage(game)" />
            <p class="name">
             {{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}
             <em :class="game.groupCode">
                <template v-if="game.groupCode === 'slot'">
                  <template v-if="game.groupCodeName.indexOf('H-') === -1">
                    {{ $t('front.gnb.slot') }}
                  </template>
                  <template v-else>
                    {{ $t('front.gnb.hotelslot') }}
                  </template>
                </template>
             </em>
            </p>
            <template v-if="game.groupCode == 'slot'">
             <a @click="slotOpen('isSlot', 'slot', game.codeName, game.code)"></a>
            </template>
          </li>
        </template>
      </ul>
    </div>
    <!--transition-group name="fade" >
      <div class="tab-content main-bg" v-bind:key="currentTab">
        <ul class="gamewrap">
          <template v-for="game in gameList" v-bind:key="game.code">
            <li v-if="currentTab == 'tab-1' || (currentTab != 'tab-1' && currentTab==game.type)"
                :class="game.groupCode">
              <img :src="getBackgroundImage(game)" />
              <p class="name">
                {{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}
                <em :class="game.groupCode">
                  <template v-if="game.groupCode === 'casino'">
                    <template v-if="game.groupCodeName.indexOf('HC-') === -1">
                      {{ $t('front.gnb.livecasino') }}
                    </template>
                    <template v-else>
                      {{ $t('front.gnb.hotelcasino') }}
                    </template>
                  </template>
                  <template v-if="game.groupCode === 'slot'">
                    <template v-if="game.groupCodeName.indexOf('H-') === -1">
                      {{ $t('front.gnb.slot') }}
                    </template>
                    <template v-else>
                      {{ $t('front.gnb.hotelslot') }}
                    </template>
                  </template>
                  <template v-if="game.groupCode === 'minigame'">
                    {{ $t('front.gnb.minigame') }}
                  </template>
                  <template v-if="game.groupCode === 'sport'">
                    {{ $t('front.gnb.sport') }}
                  </template>
                </em>
              </p>
              <template v-if="game.groupCode == 'slot'">
                <a @click="slotOpen('isSlot', 'slot', game.codeName, game.code)"></a>
              </template>

              <template v-else-if="game.groupCode == 'new'">
                <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
              </template>

              <template v-else>
                <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
              </template>

            </li>
          </template>
        </ul>
      </div>

    </transition-group-->
  </section>
  <section class="cs_Area" style="padding:10px">
      <div id="customer">
        <ul>
          <li>
            <h1><i class="fa fa-puzzle-piece" aria-hidden="true"></i> 공지사항 <span @click="goPageByName('noticeList')">+ MORE</span></h1>
            <template v-for="item in noticeList" :key="item">
              <p @click="goPageByName('noticeList')">ㆍ{{item.title}} <span>{{item.regDate}}</span></p>
            </template>
            <template v-for="item in noticeListMaxCount - noticeList.length" :key="item">
              <p>ㆍ <span> </span></p>
            </template>
          </li>
          <li>
            <h1><i class="fa fa-puzzle-piece" aria-hidden="true"></i> 실시간 입금현황 <span>+ MORE</span></h1>
            <template v-for="item in cashInList" :key="item.memId">
              <p>ㆍ{{item.memId}}<span>{{item.regDate}}</span><span class="pay">{{thousand(Number(item.cashAmt))}} 원</span></p>
            </template>
            <template v-for="item in listMaxCount - cashInList.length" :key="item">
              <p>ㆍ <span> </span><span class="pay"> </span></p>
            </template>
          </li>
          <li>
            <h1><i class="fa fa-puzzle-piece" aria-hidden="true"></i> 실시간 출금현황 <span>+ MORE</span></h1>
            <template v-for="item in cashOutList" :key="item.memId">
              <p>ㆍ{{item.memId}}<span>{{item.regDate}}</span><span class="pay">{{thousand(Number(item.cashAmt*-1))}} 원</span></p>
            </template>
            <template v-for="item in listMaxCount - cashOutList.length" :key="item">
              <p>ㆍ <span> </span><span class="pay"> </span></p>
            </template>
          </li>
        </ul>
      </div>
    <div class="btn_line">
      <button @click="goPageByName('qnaList')">문의하기</button>
      <button>게임가이드</button>
      <button>자주하는 질문</button>
      <button>24시간 상담센터</button>
    </div>
  </section>
   <!--<section style="clear:both">
  <div class="partner_line">
        <div class="swiper sSwiper">
          <div class="swiper-wrapper">
          <div class="swiper-slide"><img src="../assets/img/ca_logo1.png"></div>
          <div class="swiper-slide"><img src="../assets/img/ca_logo3.png"></div>
          <div class="swiper-slide"><img src="../assets/img/ca_logo4.png"></div>
          <div class="swiper-slide"><img src="../assets/img/ca_logo5.png"></div>
          <div class="swiper-slide"><img src="../assets/img/ca_logo6.png"></div>
          <div class="swiper-slide"><img src="../assets/img/ca_logo7.png"></div>
          <div class="swiper-slide"><img src="../assets/img/ca_logo8.png"></div>
          <div class="swiper-slide"><img src="../assets/img/ca_logo9.png"></div>
          <div class="swiper-slide"><img src="../assets/img/ca_logo10.png"></div>
          </div>
        </div>
  </div>
  </section>
 <section>
    <div style="margin-bottom:80px;padding:30px;text-align:Center; font-size: 13px;"><strong style="font-family: 'SBAggroB';font-size:11pt">문의하기 l 공지사항 l 자주하는질문 l 베팅규정 l 경기결과 l 게시판</strong><br><br><img src="../assets/img/Lsglogo.svg" height="50px"><br>©2023 • U Casino All Rights Reserved.</div>

  </section>-->

  <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
    <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
      <div class="closeWrap">
        <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
        <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
      </div>
      <img :src="item.bannerImg" />
    </div>
  </template>

</template>

<script>

import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'
import { getSlotGame } from '@/api/game'
import { isMobile } from '@/libs/utils'
import UiSwiper from '@/components/ui/UiSwiper'
import { mapState } from 'vuex'
import store from '@/store'
import { getMainCashStatus, getMainNotice } from '@/api/main'

export default {
  name: 'MIndex',
  components: {
    UiSwiper
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'commonCodeByCode',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])
  },
  data () {
    return {
      loaded: false,
      currentTab: 'tab-0',
      gameList: [],
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 5,
      noticeListMaxCount: 5
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  methods: {
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        if (image) {
          return image
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    slotOpen (event, groupCode, codeName) {
      console.log(event, groupCode, codeName)
      this.emitter.emit(event, { groupCode, codeName })
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
      console.log(this.popupList)
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    }
  },
  async created () {
    setTimeout(function () {
      const gameList = JSON.parse(JSON.stringify(this.commonCodeByCode))

      for (const item in gameList) {
        const game = gameList[item]
        let type = 'type2'
        if (game.groupCode === 'casino') {
          type = 'tab-2'
          if (game.groupCodeName.indexOf('HC-') > -1) {
            type = 'tab-3'
          }
        } else if (game.groupCode === 'slot') {
          type = 'tab-4'
          if (game.groupCodeName.indexOf('H-') > -1) {
            type = 'tab-7'
          }
        } else if (game.groupCode === 'minigame') {
          type = 'tab-6'
        } else if (game.groupCode === 'sport') {
          type = 'tab-5'
        }
        game.type = type
        this.gameList.push(game)
      }

      this.loaded = true

      if (this.$route.params.prodId && this.$route.params.type) {
        if (this.userData && this.userData.kplayId) {
          const params = {
            kplayId: this.userData.kplayId,
            gameCode: this.$route.params.prodId,
            gameGroupCode: 'slot',
            type: this.$route.params.type,
            prodId: this.$route.params.prodId
          }
          getSlotGame(params)
            .then(response => {
              const result = response.data
              if (result.resultCode === '0') {
                const data = result.data
                const gameUrl = data.gameurl

                const _width = 1100
                const _height = 680
                const _left = Math.ceil((window.screen.width - _width) / 2)
                const _top = 100
                if (isMobile()) {
                  // mobile
                  const p = window.open('/', '_blank')
                  p.location.href = gameUrl
                } else {
                  // pc
                  window.open(gameUrl, 'evolution', `width=${_width},height=${_height},left=${_left},top=${_top}`)
                }
              }
            })
        } else {
          this.onCheck('front.error.afterSignin')
        }
      }
    }.bind(this), 500)
    this.loadNotice()
    this.loadCashStatus()
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    next()
  }
}
</script>

<style scoped>
.loading {z-index: 10;position: relative;width: 100%;height: 78px;display:flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
.mainPop {position: relative;min-width: 320px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background: #fff;border: 1px solid #109eef;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 320px;height: 440px;vertical-align: bottom;object-fit: cover;object-position: center;}
</style>
<style>
.mainSlide img{width:100%;height: 200px;object-fit: cover;object-position: center;}
.mainSlide .swiper-pagination-bullets{bottom:16px;text-align:center;}
.mainSlide .swiper-pagination-bullets span:hover,.mainSlide2 .swiper-pagination-bullets span.swiper-pagination-bullet-active{opacity:1;}
.mainSlide .swiper-pagination-bullets span{width:35px;height:4px;margin:0;background:#fff;opacity:.3;border-radius:0;margin:0 !important;}
.mainSlide .swiper-slide>a{width:100%;display:block;}

.swiper-button-next {
  background-image: url('~@/assets/img/slideright.png') !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  right: 10px;
  bottom: 50%;
  z-index: 11;
}
.swiper-button-prev {
  background-image: url('~@/assets/img/slideleft.png') !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  left: 10px;
  bottom: 50%;
  z-index: 11;
}
.swiper-pagination-bullet {display: none;}
</style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/reset.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/main.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
